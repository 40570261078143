import React, { useState } from 'react';
import Layout from '../components/layout';
import Why from '../components/whysection';
import Who from '../components/whoarefor';
import { ReactTypeformEmbed } from 'react-typeform-embed'


const typeformcode = `<div class="typeform-widget" data-url="https://form.typeform.com/to/lcsWJ26h" data-hide-headers=true data-hide-footer=true style="width: 100%; height: 500px;"></div> <script> (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })() </script> <div style="font-family: Sans-Serif;font-size: 12px;color: #999;opacity: 0.5; padding-top: 5px;"> powered by <a href="https://admin.typeform.com/signup?utm_campaign=lcsWJ26h&utm_source=typeform.com-01D8JX1A4SB34PPS5TZ7KCD485-free&utm_medium=typeform&utm_content=typeform-embedded-poweredbytypeform&utm_term=EN" style="color: #999" target="_blank">Typeform</a> </div>`
const IndexPage = () => {

	const [registerModel, setregisterModel] = useState(false)
  return(
    <Layout registerModel setregisterModel>
		<section className="home-intro">
			<div className="container">
				<div className="row full-height">
					<div className="col-md-7 col-lg-6 col-black">
						<div className="inner d-flex align-items-start flex-column justify-content-center h-100">
							<div className="row">
								<div className="col-md-12 ">
									<h1>Build And Deploy Backends In Minutes</h1>
									<p className="teaser-1 text-4xl">Phasor helps devs visually create production-ready Backend API & services so that they can build, ship, and scale software faster</p>
									<span onClick={() => setregisterModel(true)} style={{ cursor:'pointer' }} className="cta-button">Register for Beta</span>
									{/* <a href="https://our.status.im/compound-finance/" target="_blank" className="announcement"><span>News</span><b>Compound Finance: Earning Interest with DeFi Lending</b></a> */}
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 mt-4 ml-auto col-lg-6 d-flex justify-content-center align-items-center">
						{/* <div className="mobile"><img	 src={'/dashscreen.png'}  /></div> */}
					</div>
				</div>
			</div>
		</section>

		<Why />

		<Who />
		{ registerModel ? <div className="overlay" onClick={() => setregisterModel(false)} style={{ position:'fixed', top: 0, left: 0, pointerEvents:'none', background:'rgba(0,0,0,0.8)', width:'100vw', height:'100vh' }}></div> : null }

			<div className={ registerModel ? 'model active' : 'model'} style={{ position:'fixed', width:'50%', top:0,  height:'100vh', background:'#fff', borderLeft:'1px solid #efefef' }}>
					<ReactTypeformEmbed style={{ height:'100vh', width:'100%', position:'relative' }} url="https://athulsuresh.typeform.com/to/lcsWJ26h" />
				</div> 
	
			 
		
    </Layout>
)};

export default IndexPage;
