import React from 'react';


const IndexPage = () => {
	
  return(
<section className="get-involved-box-links  p-b-160">
		<div className="container">
       
            <div className="row">
                <div className="col">
                <h3>Who is Phasor for</h3>
                </div>
            </div>
			<div className="row">
                
				<div className="col-xl-4">
					<div className="box-link">
						<h3 className="title"><a href="#">Developers</a></h3>
						<p>Save atleast 40% of backend development time by minimising syntax error, writing repetitive code and doing code maintainance. Debug faster with end to end visibility. Infinitely scalable.
Zero maintenance. Ship Faster.</p>
						<a href="#" className="link-arrow link-arrow-white">Learn More</a>
					</div>
				</div>
				<div className="col-xl-4">
					<div className="box-link">
						<h3 className="title"><a href="#">Startups</a></h3>
						<p>Quickly build your MVP and test your product-market fit. You don't need to spend time and money on hiring backend engineers. Phasor allows you to do rapid iterations and gives you full control over your product.</p>
						<a href="#" className="link-arrow link-arrow-white">Learn More</a>
					</div>
				</div>
				<div className="col-xl-4">
					<div className="box-link">
						<h3 className="title"><a href="#">Enterprise</a></h3>
						<p>Automate your internal or external processes with modern web apps. Build API's without any code that makes your teams effective without using resources from the backend developer department.</p>
						<a href="#" className="link-arrow link-arrow-white">Learn More</a>
					</div>
				</div>
			</div>
		</div>
	</section>
)};


export default IndexPage;
