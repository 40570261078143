import React from 'react';


const IndexPage = () => {
	
  return(
    <section className="home-about p-t-160 p-b-160">
		{/* <div className="container">
			<div className="row d-flex justify-center">
				<div className="col-md-6">
					<div className="inner">
						<h3>Why Phasor</h3>
						<p className="teaser-2">For the last 20 years creating web backend applications didn’t change much. Surely, it became easier to create websites and online shops using site builders, but what about custom solutions? For features like user authentication or data entry from a database, you still need to rely on developers.
<br /><br />
Yet there are simply not enough developers to cater to the need for new software. Building custom software is also a long and costly process. If you fail to clearly express your needs and vision to the developer, you might be disappointed with the end result.</p>
						
					</div>
				</div>
				
			</div>
		</div> */}


	

        <div className="container p-t-160 p-b-100">
			<div className="row d-flex justify-center">
				<div className="col-md-6 pr3">
					<div className="inner">
						<h3>Build workflows seemlessly in minutes</h3>
						<p className="teaser-2">Phasor gives you building blocks like triggers, API requests, and database queries, giving your developer team the power to create production-ready workflows in minutes.</p>
						
					</div>
				

				</div>
				<div className="col-md-5">
						<img src="/illu-f1.png" style={{ width:'100%'}} />
					</div>	
				
			</div>
		</div>


		<div className="container p-t-160 p-b-100">
			<div className="row d-flex justify-center items-center">
				<div className="col-md-6">
					<img src="/problock.png" style={{ width:'100%', marginLeft:'-200px' }} />
				</div>
				<div className="col-md-6">
					<div className="inner">
						<h3>Integrate with any API or datasource</h3>
						<p className="teaser-2">Phasor makes it easy to build workflows with your data, wherever it lives. Choose from our growing library of API and database integrations or connect directly to any HTTP API.</p>
						
					</div>
				</div>
				
			</div>
		</div>


		<div className="container p-t-160 p-b-100">
			<div className="row d-flex justify-center">
				<div className="col-md-6">
					<div className="inner">
						<h3>Ship faster with less code</h3>
						<p className="teaser-2">Give attention back to your users by reducing the workload of making and running backends. Build and ship backends in hours and not weeks or months using our visual development platform. Customizable building blocks allow you to go from idea to production at a faster pace than traditional methods.</p>
						
					</div>
				</div>
				
			</div>
		</div>
	</section>
)};


export default IndexPage;
